.progress-bar-vertical {
    width: 14px;
    height: 110px;
    display: flex;
    align-items: flex-end;
    border-radius: 7px;
    background-color: rgba(0,0,0,0.1);
  }
  
  .progress-bar-vertical .progress-bar {
    width: 100%;
    height: 0;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
    border-radius: 7px;
    background-color: #000000;
  }

  .progress{
    margin-bottom: 10px;
  }