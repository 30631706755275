/** @format */

@import "../node_modules/video-react/styles/scss/video-react";
@import "../node_modules/animate.css/animate.min.css";
@import "./theme.scss";

@font-face {
  font-family: "SofiaPro";
  src: local("SofiaPro"), url("./fonts/sofiapro-light.otf") format("OpenType");
}


// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";

* {
  margin: 0;
  padding: 0;
}

.fa {
  color:$primary
}

.micon {
  color: $primary
}

.themeclr {
  color: $primary
}


.rs-btn-primary {
  background-color: $primary!important;
}
.rs-btn-ghost {
  border: 1px solid $primary!important;
  color: $primary!important;
}

a:hover {
  color: $black
}

.stroke {
  stroke: $primary
}

.stroke-fil {
  stroke: $primary;
  fill: $primary
}

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

#phoneNumber::-webkit-inner-spin-button,
#phoneNumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.login {
  background-image: url('./assets/login-banner.jpg');
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  @media #{$xs-layout} {
    justify-content: center;
    ;
  }

  &__box {
    width: 450px;
    margin-right: 5em;
    background-color: #fff;
    text-align: center;
    padding: 3em 2.2em;
    border-radius: 1.8em;
    -webkit-box-shadow: 10px 0px 54px -32px rgba(53, 51, 54, 1);
    -moz-box-shadow: 10px 0px 54px -32px rgba(53, 51, 54, 1);
    box-shadow: 10px 0px 54px -32px rgba(53, 51, 54, 1);

    @media #{$xs-layout} {
      width: 100%;
    }
  }
  &__box_password {
    width: 450px;
    // margin-right: 5em;
    background-color: #fff;
    // text-align: center;
    padding: 3em 2.2em;
    border-radius: 1.8em;
    -webkit-box-shadow: 10px 0px 54px -32px rgba(53, 51, 54, 1);
    -moz-box-shadow: 10px 0px 54px -32px rgba(53, 51, 54, 1);
    box-shadow: 10px 0px 54px -32px rgba(53, 51, 54, 1);

    @media #{$xs-layout} {
      width: 100%;
    }
  }
  &__box_message {
    width: 450px;
    margin-top: 2em;
    background-color:$primary;
    display: flex;
    justify-content: space-between;
    // text-align: center;
    padding: 2em;
    border-radius: 1.8em;
    -webkit-box-shadow: 10px 0px 54px -32px rgba(53, 51, 54, 1);
    -moz-box-shadow: 10px 0px 54px -32px rgba(53, 51, 54, 1);
    box-shadow: 10px 0px 54px -32px rgba(53, 51, 54, 1);
p{
  flex-grow: 5;
  color:#fff
}
button{
  background-color: #fff;
  color: $primary;
}
    @media #{$xs-layout} {
      width: 100%;
    }
  }
}

.caps {
  text-transform: capitalize;
}

.non-cap:first-letter {
  text-transform: capitalize;
}

.error {
  background: $primary;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  display: inline-block;
  margin-bottom: 20px;
}

ul,
ol,
hr,
div,
body {
  margin: 0;
  font-family: "SofiaPro";
}

b,
strong {
  font-weight: 700;
}

.loader {
  position: absolute;
  top: 50%;
  left: 42%;
  z-index: 1000000;

  img {
    width: 200px;
    height: 200px;
  }
}

.signIn {
  display: flex;
  justify-content: center;
  margin: 20px 0px;

  // button{
  //   margin:0px
  // }
}

#signin {
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  padding-right: 20px;
}

.nav-dashboard {
  color: $primary;

  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding-right: 20px;
  padding-top: 10px;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

button.disabled {
  background-color: #a8a8a8 !important;
  color: #ffffff !important;
  pointer-events: none;
  cursor: not-allowed;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

button:focus {
  outline: none;
}

button.hollow {
  color: $primary;
  background-color: transparent;
  border: 1px solid $primary;

  span {
    padding-left: 0;
    padding-right: 8px;
  }
}

.split-two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;

  .form-group {
    // width: 48%;
    width: 100%;
    // padding-right: 10px;
  }
}

button {
  padding: 10px 16px 11px;
  color: #ffffff;

  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  border-radius: 17.5px;
  background-color: $primary;
  box-shadow: none;
  border: none;
  white-space: nowrap;
  min-width: 100px;

  span {
    display: inline-block;
    position: relative;
    padding-left: 8px;
    top: 1px;

    i {
      width: 7px;
      height: 11px;
    }
  }
}

.load-more {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.ptr {
  cursor: pointer;
}

.notify {
  padding: 15px 16px 15px;
  z-index: -1;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 2px solid #dddddd;
  border-radius: 0.5px;
  color: #4d4d4d;

  .notify-header {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 5px;
  }

  .notify-message {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
    word-break: break-word !important;
  }
}

.notify.unread {
  background-color: #f5f5f5;
  color: $primary !important;
  text-align: justify;
}

.notify:hover {
  color: $primary !important;
}

.header {
  padding-top: 22px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left-half {
    position: relative;
    top: 12px;
    cursor: pointer;
  }

  .right-half {
    display: flex;
    align-items: center;

    ul.notification {
      height: auto !important;
      width: 300px !important;
      padding: 14px 0 14px !important;
      transform: translate3d(-300px, 0px, 0px) !important;

      .notify {
        padding: 15px 16px 15px;
        z-index: -1;
        box-sizing: border-box;
        width: 100%;
        border-bottom: 2px solid #dddddd;
        border-radius: 0.5px;
        color: #4d4d4d;

        .notify-header {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 16px;
          margin-bottom: 5px;
        }

        .notify-message {
          word-break: break-all;
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 18px;
        }
      }

      .notify.unread {
        background-color: #f5f5f5;
        color: $primary !important;
      }

      .notify:hover {
        color: $primary !important;
      }

      .all-notification {
        margin-top: 10px;
        text-align: center;
        padding: 0;
      }
    }

    .bell {
      margin-top: 10px;
    }

    a:hover {
      color: $primary;
      text-decoration: underline;
    }

    .dropdown {
      ul {
        padding: 10px 0;
        top: 10px !important;
        height: 179px;
        width: 171px;
        border-radius: 14px;
        border: none !important;
        background-color: #ffffff;
        box-shadow: 10px 10px 30px 1px rgba(0, 0, 0, 0.1);

        li {
          padding: 10px 0 0 0;
          color: #a8a8a8;

          font-size: 16px;
          letter-spacing: 0;
          line-height: 17px;

          a {
            padding: 3px 10px;
          }

          a span {
            padding-left: 10px;
          }

          a img {
            width: 20px;
            height: 15px;
          }
        }

        li a:hover {
          color: $primary;
        }
      }
    }

    #nav-icon4 {
      margin: 12px 0 0 20px;
      width: 24px;
      height: 19px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
    }

    #nav-icon4 span {
      display: block;
      position: absolute;
      height: 1.26px;
      width: 100%;
      background: #a8a8a8;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    /* Icon 4 */

    #nav-icon4 span:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    #nav-icon4 span:nth-child(2) {
      top: 9px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    #nav-icon4 span:nth-child(3) {
      bottom: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    #nav-icon4.open span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: -3px;
      left: 8px;
    }

    #nav-icon4.open span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    #nav-icon4.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 15px;
      left: 8px;
    }
  }
}

.close {
  position: absolute;
  right: -25px;
  top: 8px;
  font-size: 30px;
}

.welcome {
  color: #000000;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 44px;
  margin-bottom: 24px;
}

.search-action {
  display: flex;
  align-items: center;
}

.search {
  position: relative;

  input {
    width: 269px;
    border-radius: 23px;
    background-color: #f4f4f4;
    outline: none;
  }

  .search-text {
    background-color: #ffffff;
    box-shadow: 10px 10px 30px 1px rgba(0, 0, 0, 0.1);
    color: $primary;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 17px;
    outline: none;
  }

  input:focus {
    background-color: #ffffff;
    box-shadow: 10px 10px 30px 1px rgba(0, 0, 0, 0.1);
    color: $primary;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 17px;
    outline: none;
  }
}

.search {
  display: inline-block;
  position: relative;
  color: $primary;
  top: -2px;
}

.search:after {
  font-family: "FontAwesome";
  content: "\f002";
  top: 10px;
  position: absolute;
  right: 20px;
  transform: rotate(90deg);
  font-size: 18px;
}

.dashboard {
  .course-info {
    display: flex;
    height: 304px;
    margin-bottom: 48px;

    .left-half-video {
      width: 50%;

      iframe,
      video {
        height: 100%;
        width: 100%;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
    }

    .right-half-summary {
      width: 50%;
      background-color: $backgroud;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      padding: 60px 90px 60px 56px;

      .subTitle {
        color: #000000;

        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 28px;
        margin-bottom: 16px;
      }

      .subText {
        color: #4d4d4d;

        font-size: 12px;
        letter-spacing: 0;
        line-height: 19px;
        margin-bottom: 30px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .action-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .user-course-stats {
    display: flex;
    height: 292px;
    justify-content: space-between;
    margin-bottom: 48px;

    .class-info {
      border-radius: 12px;
      background-color: #f4f4f4;
      color: #4d4d4d;
      padding: 32px 58px 48px 40px;
      width: 391px;

      .heading {
        color: #000000;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 28px;
        margin-bottom: 24px;
      }

      .class-stats {
        display: flex;
        flex-direction: row;

        .progress-meter {
          margin-bottom: 32px;
          margin-right: 32px;

          .circle-size {
            width: 64px;
            height: 64px;
            color: #ffffff;
          }
        }

        &-title {
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 20px;
          margin-bottom: 3px;
          padding-top: 7px;
        }

        &-summary {
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 19px;
        }
      }
    }

    .module-info {
      box-sizing: border-box;
      height: 293px;
      width: 482px;
      border: 1px solid rgba(119, 19, 66, 0.1);
      border-radius: 12px;
      padding: 32px 34px 22px 36px;

      .heading {
        color: #000000;

        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 28px;
        margin-bottom: 24px;
      }
    }

    .modules {
      display: flex;
      margin-bottom: 16px;
      overflow-x: scroll;
     

      .module {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2px 10px;

        .module-name {
          color: #000000;
          text-align: center;
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 10px;
          margin-bottom: 6px;
        }

       

        .module-completion {
          color: #000000;

          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
    }

    .modules::-webkit-scrollbar {
      height: 10px;
      border-radius: 10px;
    }
  
/* Track */
.modules::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.modules::-webkit-scrollbar-thumb {
  background: $primary; 
  border-radius: 10px;
}


    .info {
      color: #4d4d4d;

      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 19px;
      padding: 0;
    }
  }
}

.x {
  position: relative;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  flex-flow: row wrap;

  .card {
    border-radius: 12px;
    border: none;
    // box-shadow: 0 10px 20px 2px rgba(77, 77, 77, 0.1);
    width: 282px;
    height: 439px;
    margin: 0 10px;

    .card-body {
      padding: 20px;

      img {
        margin-bottom: 16px;
      }

      .card-title {
        color: $primary;

        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        margin-bottom: 4px;
      }

      .card-text {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 19px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 0;
      }

      .learn-more {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 19px;
        color: $primary;
      }
    }
  }

  .card:hover {
    color: #ffffff;
    background-color: $primary;
    cursor: pointer;

    .card-title {
      color: #ffffff;
    }

    .learn-more {
      color: #ffffff;
      text-decoration: underline;
    }
  }
}

.course-insights {
  display: flex;
  // justify-content: space-between;
  padding-top: 47px;
  padding-bottom: 49px;

  .course-video {
    margin-right: 65px;

    iframe,
    video {
      width: 403px;
      height: 472px;
      border-radius: 12px;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .description {
    padding-right: 64px;
    padding-top: 25px;

    .course-name {
      color: #000000;

      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 44px;
      margin-bottom: 11px;
    }

    .author {
      color: #4d4d4d;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 13px;
      margin-bottom: 11px;
    }

    .summary {
      color: #4d4d4d;

      font-size: 12px;
      letter-spacing: 0;
      line-height: 19px;
    }

    .begin {
      margin-top: 27px;

      .enrol {
        color: $primary;

        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        margin-left: 85px;
        margin-top: 8px;
      }
    }

    .meet {
      display: inline-block;
      position: relative;
      left: 300px;
      padding-top: 12px;
      color: #4d4d4d;

      font-size: 12px;
      letter-spacing: 0;
      line-height: 13px;
    }
  }
}

.course-overview {
  padding-top: 40px;
  background-color: #ffffff;
  width: 1024px;
  margin: 0 auto;
  background-color: #ffffff;
  margin-bottom: 60px;

  h2 {
    padding-left: 103px;
    color: #4d4d4d;

    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .modules {
    margin-bottom: 32px;

    padding: 10px 134px 10px 103px;

    .num {
      color: #4d4d4d;

      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 10px;
      margin-bottom: 12px;
    }

    .heading {
      color: $primary;

      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 6px;
    }

    .summary {
      color: #4d4d4d;

      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 8px;
    }

    .stats {
      display: flex;

      .stat {
        padding-right: 32px;
        text-align: center;

        .stat-label {
          color: $primary;

          font-size: 12px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 18px;
        }

        .stat-value {
          color: $primary;

          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
    }
  }

  .modules:nth-child(odd) {
    border-radius: 5px;
    background-color: rgba(119, 19, 66, 0.05);
  }
}

.paymet-partnerts {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  img:nth-child(1) {
    width: 160px;
    margin-right: 10px;
    // height: 60px;
    // object-fit: contain;
  }

  img:nth-child(2) {
    width: 100px;
    // height: 48px;
    // object-fit: contain;
  }
}

.footer-container {
  width: 100%;
  background-color: #4d4d4d;
  padding: 20px 28px;
  margin-top: 30px;

  // position: fixed;
  // bottom: 0px;
  // left: 0px;
  // right: 0px;
  .footer-grid {
    display: grid;
    // grid-template-columns: 1fr 0.7fr 0.7fr 1fr;
    grid-template-columns: repeat(auto-fill, minmax(250px, 0.4fr));
    grid-auto-rows: 200px;

    div {
      color: #fff !important;

      p {
        color: #fff !important;
        margin-top: 10px;
        line-height: 1.7rem;
        font-size: 12px !important;
      }

      h4 {
        color: #fff !important;
        font-size: 16px;
      }

      ul {
        list-style: none;

        li {
          list-style: none;
          text-decoration: none;
          // margin-top: 10px;
        }
      }

      a {
        text-decoration: none;
        color: #fff !important;
        margin: 10px 0px !important;
      }
    }

    div:nth-child(1) {
      padding: 20px 28px;
      width: 80%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      div {
        position: relative;

        img {
          position: absolute;
          left: -30px;
          top: 0px;
        }
      }
    }

    div:nth-child(2) {
      position: relative;
      padding: 20px 28px;

      p {
        margin-top: 10px;
      }
    }

    div:nth-child(3) {
      position: relative;
      padding: 20px 28px;
    }

    .help-box {
      padding: 20px 28px;

      div {
        padding: 0px;
        margin: 15px 0px;

        .rs-btn {
          background-color: #fff !important;
          color: $primary;
          border-radius: 24px !important;
          font-weight: 600 !important;
        }
      }
    }

    .social-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 !important;

      .icons {
        cursor: pointer;
        color: #fff !important;
        margin-right: 10px;
        font-size: 20px;
      }
    }
  }

  .border-line {
    border: 1px solid #fff;
    transform: rotate(90deg);
    width: 140px;
    position: absolute;
    right: -55px;
    top: 94px;
    margin: auto;
  }
}

.modal-btn-box {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}

.input-box {
  padding-top: 15px;
}

.tooltip-rs {
  background-color: #fff !important;
}

.rs.tooltip {
  // padding: 16px 26px;
}

.action-btn-info {
  margin-left: 20px;
}

.action-btn-info:hover {
  color: $primary !important;
}

.rs-tooltip-inner {
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: 8px 8px 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  border-left: 10px solid $primary;
  text-align: left;
  height: 100%;

  p {
    text-align: left !important;
    line-height: 19px !important;
    opacity: 0.8 !important;
    margin-top: 10px;
  }

  h4 {
    font-size: 14px !important;
    line-height: 12px !important;
    font-weight: 600 !important;
    text-align: left !important;
    opacity: 0.8 !important;
  }

  div {
    padding: 16px;
    background-color: #fff !important;
  }
}

.rs-btn {
  border-radius: 18px;
  font-size: 16px !important;
  font-weight: 600 !important;
  // font-weight: 600 !important;
}

.rs-btn.rs-btn-icon>.rs-icon {
  // width: 50px !important;
}

.rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 17px !important;
  padding-right: 37px !important;
}

.n-badge {
  background-color: #f8d1e3;
  color: $primary;
  border-radius: 3px !important;
  font-size: 10px !important;
  margin-left: 15px;
  line-height: 20px;
}

.not-valid {
  background-color: #fad9d9 !important;
  // color: #B31818;
}

.f-center {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .card .course-video .description {
    flex: 1;
    flex-basis: 100%;
  }

  .course-insights {
    flex-wrap: wrap;
  }

  .footer-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 0.4fr)) !important;

    .border-line {
      display: none;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .container {
    width: 1024px;
    margin: 0 auto;
    padding: 0 56px;
  }
}

.stepper {
  div>div>div>div {

    span,
    a {
      position: relative;
      bottom: 5px;
      cursor: pointer;
    }
  }
}

.Toastify__toast--error {
  background-color: $primary !important;
  color: #fff !important;
  font-size: 16px;
  padding: 15px 20px 15px 15px !important;
}

.Toastify__toast.Toastify__toast--error {
  button {
    margin: 0 !important;
    min-width: 0;
  }
}

.Toastify__toast-body {
  padding-right: 15px;
}

@media only screen and (max-width: 450px) {
  .header .right-half ul.notification {
    transform: none !important;
  }

  .footer-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 0.4fr)) !important;

    .border-line {
      display: none;
    }
  }

  .nav-dashboard {
    padding-top: -5px;
  }

  .dashboard .course-info {
    height: auto;

    .left-half-video {
      margin-bottom: 10px;

      video {
        border-radius: 15px;
      }
    }

    .right-half-summary {
      padding: 60px 20px 60px 20px;
    }

    .right-half-summary,
    .left-half-video {
      width: 100%;
      border-radius: 15px;
    }
  }

  .dashboard .course-info {
    flex-direction: column;
    margin-bottom: 15px;
  }

  .dashboard .user-course-stats {
    flex-direction: column;
    height: auto;
  }

  .dashboard .user-course-stats {
    .class-info {
      margin-bottom: 15px;
    }

    .class-info,
    .module-info {
      padding: 32px 20px 48px 20px;
      width: 100%;
      height: auto;
    }
  }

  .x {
    justify-content: center;
  }

  .welcome {
    flex-direction: column;

    .search-actions {
      margin-top: 15px;
    }
  }

  .your-courses ul {
    text-align: center;
  }

  .forum-post {
    // display: inline-block;
    flex-direction: column;
    padding: 15px 15px 15px 15px !important;
    margin-bottom: 20px !important;

    .reply {
      float: right;
    }

    .icon {
      display: none;
    }
  }

  .welcome .hollow {
    margin-top: 20px;
  }

  .text-container {
    padding: 25px 20px 25px !important;

    textarea {
      width: 100% !important;
      padding: 12px;
    }
  }

  .enroll .modal-dialog {
    max-width: 365px;
  }

  .course-insights {
    flex-direction: column;

    .course-video {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;

      video {
        width: 100%;
        height: auto;
      }
    }

    .description {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;

      .begin {
        text-align: center;
      }
    }
  }

  .header .left-half {
    margin-right: 0;
  }

  .course-overview {
    width: 100%;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    .modules {
      width: 100%;
      padding: 15px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .content .video video {
    width: 100%;
  }

  .content .assignment {
    flex-direction: column !important;

    .assignment-details {
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      // width: 100%;
    }
  }

  .quiz-instructions {
    width: 100%;
    padding: 15px 25px !important;
    margin-right: auto;
    margin-left: auto;
  }

  .loader {
    left: 23% !important;
  }

  .paymet-partnerts {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    img:nth-child(1) {
      width: 160px;
      margin-right: 5px;
      // height: 60px;
      // object-fit: contain;
    }

    img:nth-child(2) {
      width: 100px;
      // height: 48px;
      // object-fit: contain;
    }
  }

  .payments .left-half {
    margin-right: 0 !important;
  }

  .action-btn {
    justify-content: space-between;
  }
}

// .assignment-details1 {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   width: 100%;
// }

.payments {
  .payment-message {
    background-color: #fad9d9 !important;
    border-radius: 5px;
    padding: 15px;
    display: grid;
    grid-template-columns: 20px auto;
    align-content: center;
    margin-bottom: 10px;

    .payment-msg-icon {
      color: #b31818;
    }

    p {
      margin: 0px;
      color: #b31818 !important;
      font-size: 12px;
      padding-left: 5px;
      text-align: left !important;
    }

    span {
      font-weight: 600 !important;
      // padding-left: 5px;
    }
  }

  .rs-message .rs-message-container {
    padding: 10px !important;
  }

  .rs-message-body {
    color: $primary !important;
  }
}

.dobex {
  font-family: "SofiaPro";
  src: local("SofiaPro"), url("./fonts/sofiapro-light.otf") format("OpenType");
  font-size: 12px;
  font-weight: 700;
  color: #e9e9e9 !important;
}

video::-webkit-media-controls {
  overflow: hidden !important;
}

video::-webkit-media-controls-enclosure {
  width: calc(100% + 32px) !important;
  margin-left: auto !important;
}

.video-react-controls-enabled {
  padding-top: 0px !important;
}

.video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  height: 100% !important;
}

.video-react .video-react-video {
  position: relative !important;
  height: 100% !important;
}

.dashboard .video-react .video-react-video {
  position: relative !important;
  height: 300px !important;
}

.lesson-player .video-react .video-react-video {
  position: relative !important;
  height: 100% !important;
}

.video-react .video-react-big-play-button {
  position: absolute;
  // top: 140px !important;
  // left: 170px !important;
  left: 0px !important;
  right: 0px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  bottom: 0px !important;
}

// .lesson-player .video-react .video-react-big-play-button{
//   position: absolute;
//   // top: 220px !important;
//   // left: 360px !important;
//   left: 0px !important;
//   right: 0px !important;
//   margin-right: auto !important;
//   margin-left: auto !important;
//   margin-top: auto !important;
//   margin-bottom: auto !important;
//   bottom: 0px !important;
// }
// .course-video .video-react-big-play-button{
//   position: absolute;
//   // top: 150px !important;
//   // left: 150px !important;
//   left: 0px !important;
//   right: 0px !important;
//   margin-right: auto !important;
//   margin-left: auto !important;
//   margin-top: auto !important;
//   margin-bottom: auto !important;
//   bottom: 0px !important;
// }

.phoneNumber {
  // padding-top: 1.2rem !important;
}

.action-btn .containers {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.register {
  .rs-btn {
    border-radius: 23px !important;
    background-color: #eee !important;
    font-size: 16px !important;
    color: #000 !important;
    z-index: -999;
  }

  .dropdown-inline {
    position: relative;
    z-index: 6;
    max-width: calc(100% - 6px);
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
  }
}

.rs-picker-default .rs-picker-toggle {
  background-color: #eee !important;
  border-color: none !important;
  z-index: 99 !important;
  font-weight: 400 !important;
  // z-index: -999;

  .rs-picker-toggle-value {
    color: #000000 !important;
  }
}

@media only screen and (max-width: 600px) {
  .dropdown-inline {
    position: relative;
    z-index: 6;
    max-width: calc(100% - 6px);
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px !important;
  }

  .phoneNumber {
    // padding-top: 1.3rem !important;
  }

  .header .right-half .dropdown ul {
    position: absolute;
    left: 0px !important;
    right: 0px !important;
    margin-left: auto;
    // margin-right: auto;
    // transform: none !important;
  }

  .header .right-half ul.notification {
    // transform: translate3d(0px, 0px, 0px) !important;
  }
}

@media only screen and (max-width: 400px) {
  .arrow {
    position: absolute;
    right: -9px;
  }
}

.rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right>.rs-icon {
  // left: 108px !important;
  right: 0;
}


.disabled-modules {
  cursor: not-allowed;
  background-color: #F2F3F1;
  border-radius: 12px;

  .heading {
    cursor: not-allowed;

  }
}




//----------------Landing-Page----------------
.landing-navbar {
  .nav-bar {
    color: #fff !important;
    height: 66px;
    padding: 18px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // background-color: #fff;
    justify-content: space-between;



    .brand-box {
      cursor: pointer;

      img {
        height: 40px;
        object-fit: cover;
      }
    }

    .menu-icon {
      display: none;
      color: $primary;
      transition: 1s ease-out;
    }

    .nav-menu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-decoration: none;
      list-style: none;
      display: flex;
      flex-direction: row;
      margin: 0px;

      .nav-links {
        list-style: none;
        padding: 0px 22px;
        color: $black !important;
        cursor: pointer;
      }

      li>a {
        list-style: none;
        text-decoration: none;
      }

      .nav-links:hover {
        color: $primary !important;
        transition: 0.3s ease-in-out;
      }

      .right-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        // position: absolute;
        // right: 25px;
        // top:10px;

        div {
          padding: 0px 14px;
        }
      }

      .mobile-menu {
        display: none;
      }
    }


  }
}

.landing {
  .container {
    padding: 0px !important;
  }


  .landing-banner {
    height: 600px;
    position: relative;

    img {
      object-fit: cover;
      height: 600px;
      width: 100%;
     @media only screen and (min-width: 320px) and (max-width: 768px) {
        object-fit: contain
      }
     
    }
    
    &__overlay {
      position: absolute;
      top: 100px;
      left: 0px;
      right: 0px;
      height: 500px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
      padding: 28px 42px;

      &--header {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        // padding: 28px 32px;
        h1 {
          text-transform: capitalize;
          font-size: 5em;
          color: #fff;
          line-height: 1em;
          text-shadow: 1px 1px $black;
        }
      }
    }
  }

}


.info {
  padding: 12px;

  .col-md-6 {
    margin: 0px !important;
    padding: 0px !important;
  }

  &__info-text {
    background-color: $backgroud;
    padding: 46px;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    h3 {
      margin-bottom: 0.8em;
    }

    p {
      font-size: 1.2em;
      margin-bottom: 1em;
    }
  }

  &__image {
    // padding: 15px;

    img {
      width: 100%;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;

      @media #{$xs-layout} {
        border-radius: 0px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;

      }
    }
  }

}



@media screen and (max-width: 600px) {
  .landing {
    .landing-navbar {
      .nav-bar {
        position: relative;

        .brand-box {
          img {
            height: 32px;
          }
        }

        .nav-menu {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 25vh;
          position: absolute;
          top: 67px;
          left: -150%;
          opacity: 1;
          transition: all 0.5s ease;
          padding: 28px 14px;

          .right-box {
            display: none;
          }

          .mobile-menu {
            display: block;

            .rs-btn {
              border: 1px solid #fff !important;
            }

            div {
              margin: 12px 0px;
            }
          }
        }

        .active {
          background-image: linear-gradient(to top, rgba(119, 19, 66, 0), rgba(119, 19, 66, 0.8));
          // background-color: $primary !important;
          color: $black !important;
          left: 0;
          opacity: 1;
          z-index: 99;
          transition: all 0.5s ease;
          text-align: center;


        }

        .nav-links {
          padding: 14px !important;

        }

        .nav-links:hover {
          background-color: $primary;
          width: 100%;
          display: table;
          color: $black !important;
        }

        .brand-box {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(25%, 50%);
        }

        .menu-icon {
          display: block;
          position: absolute;
          top: 0px;
          right: -25px;
          transform: translate(-100%, 30%);
          cursor: pointer;
        }

      }
    }

    .landing-banner {
          &__overlay {
        padding: 20px;

        &--header {
          align-items: flex-start !important;

          h1 {
            font-size: 3.2em;
            margin: 0px !important;
          }
        }
      }
    }




    .browse-course {
      padding: 12px 20px 12px 0px;

      .course-container {
        .card {
          width: 100% !important;
        }
      }
    }
  }


  .rs-tooltip {
    left: 0px !important;
    // right: 0px !important;
  }

  .rs-tooltip[class*='placement-left'] {
    margin-left: 0px !important;
  }

  .info {

    .video-react-video {
      background-color: transparent !important;

      .video-react-poster {
        background-color: transparent !important;
      }
    }


    &__info-text {

      border-top-right-radius: 0px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;


    }
  }
}



.rhap_container {
  box-shadow: none !important;

  background-color: transparent !important;

  .rhap_additional-controls {
    display: none !important;
  }

  .rhap_volume-container {
    display: none !important;
  }

  .rhap_main-controls {
    width: 100% !important;
    display: flex;
    justify-content: space-between !important;
    flex-direction: row !important;
    align-items: center !important;

    button {
      svg {
        color: $primary !important;
      }
    }
  }

  .rhap_progress-bar {
    div {
      background-color: $primary;
    }

    .rhap_download-progress {
      background-color: tint($primary, 70%);
    }
  }

  .rhap_current-time {
    color: $primary !important;
  }

  .rhap_total-time {
    color: $primary !important;

  }
}



.savedCards {

  &-body {
    padding: 28px 20px;
  }

  &-header {
    display: flex;
    justify-content: center;
  }

  &-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &-btnBox {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 12px 32px;
  }
}

.close {
  z-index: 999999;
}

.terms {
  padding: 0px 56px;
  // margin-top: -200px;

  @media #{$xs-layout} {
    margin: 0px;
    padding: 0px 20px;
  }
}


// /* Chrome, Safari, Edge, Opera */
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// /* Firefox */
// input[type=number] {
//   -moz-appearance: textfield;
// }