@import "../../theme.scss";

.quiz {
  background-color: rgb(244, 244, 244);
  .attempt {
    color: #000000;

    font-size: 12px;
    font-style: italic;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 13px;
    margin-bottom: 11px;
  }
  .quiz-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .exam-of {
      color: #000000;

      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 44px;
    }

    .time-remain {
      color: $primary;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 28px;
      text-align: right;
    }
  }
}

.questions {
  margin-top: 24px;
  margin-bottom: 60px;

  ol {
    margin-left: 12px;
  }
  .question {
    color: #4d4d4d;

    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 15px;
  }

  .options {
    .radio-item {
      display: flex;
      position: relative;
      // padding: 0 6px;
      margin: 5px 0 0;
    }

    .radio-item input[type="radio"] {
      display: none;
    }

    .radio-item label {
      color: #4d4d4d;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 28px;
    }

    .radio-item label:before {
      content: " ";
      display: inline-block;
      position: relative;
      top: 3px;
      left: -8.5px;
      width: 13px;
      height: 13px;
      border-radius: 11px;
      border: 1px solid #4d4d4d;
      background-color: transparent;
    }

    .radio-item input[type="radio"]:checked + label:after {
      border-radius: 11px;
      width: 7px;
      height: 7px;
      position: absolute;
      top: 11px;
      left: 4.5px;
      content: " ";
      display: block;
      background-color: $primary;
    }

    label {
      color: #4d4d4d;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      padding-left: 10px;
    }

    textarea {
      box-sizing: border-box;
      height: 105px;
      width: 100%;
      border: 1px solid #a8a8a8;
      border-radius: 8px;
      padding: 10px;
      resize: none;
      margin-bottom: 30px;
      font-size: 16px;
    }
  }
}

/* The container */
.check-box-container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px !important;
}

/* Hide the browser's default checkbox */
.check-box-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1.5px;
  left: 0px;
  height: 15px;
  width: 15px;
  border: 1px solid #4d4d4d;
  border-radius: 3px;
  background-color: #ffffff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-box-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-box-container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 8px;
  border: solid $primary;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.exam-heading {
  color: #000000;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 44px;
  margin-bottom: 16px;
  text-transform: capitalize;
}

.exam-info {
  color: #4d4d4d;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 24px;
}

.attempt-details {
  color: #4d4d4d;
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 2.67px;
  line-height: 16px;
  margin-bottom: 24px;
}

.quiz-instructions {
  padding: 65px 72px 38px;
  border-radius: 12px;
  background-color: #f4f4f4;
}

.result-heading {
  color: #000000;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 44px;
  margin-bottom: 16px;
}

// button {
//   margin-right: 20px;
// }
