@font-face {
  font-family: SegoeUI;
  src: local("Sofia Pro"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2)
      format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff)
      format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf)
      format("truetype");
  font-weight: 100;
}

.caps{
  text-transform: capitalize
}
@import "../../theme.scss";


.your-courses {
    span {
      padding-right: 4px;
      color: #4d4d4d;
      
      font-size: 12px;
      letter-spacing: 0;
      line-height: 13px;
    }
    ul {
      // list-style-type: none;
      // display: inline-block;


    list-style-type: none;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    max-width: 100%;
    overflow-x: scroll;

      li {
        text-transform: uppercase;;
        cursor: pointer;
        display: inline-block;
        margin: 0 12px;
        padding-bottom: 7.5px;
        color: #4D4D4D;
        
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 13px;
      }
      li.active {
        border-bottom: 2px solid $primary;
        border-radius: 0.5px;
        color: $primary;

      }
      li:first-child{
        margin-left: 0 ;
      }
    }
    ul::-webkit-scrollbar {
      display: none !important;
      // width: 1em;
      // border: none !important;
    }
    // ul::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
    // }
    // ul::-webkit-scrollbar-thumb {
    //   background-color: #f4f4f4;
    //   outline: 1px solid slategrey;
    // }
  }

  hr {
    position: relative;
    top: -3px;
    z-index: -1;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 2px solid #4d4d4d;
    border-radius: 0.5px;
    opacity: 0.2;
    margin-bottom: 45.5px;
  }

  @media only screen and (max-width: 600px) {
    .your-courses ul{
      display: flex;
      overflow-y: scroll;
    }


    .your-courses ul li{
      margin: 10px 10px !important;
    }
    .your-courses ul{
      padding-top: 10px;
    }
    hr{
      top: -12px;
    }
  }