@import "../../theme.scss";

.input-field {
  font-size: 16px;
  border-radius: 23px;
  background-color: #eee;
  letter-spacing: 0;
  height: 36px;
  color: black;
  padding: 16px 24px;
  border: none;
}

.select {
  font-size: 16px;
  border-radius: 23px;
  background-color: #eee;
  letter-spacing: 0;
  height: 36px;
  color: black;
  border: none;
  padding-left: 24px;
  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.select.minimal:focus {
  background-image: linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

option {
  height: 50px;
}

.arrow {
  position: relative;
  height: 15px;
  width: 15px;
}
.arrow::before,
.arrow::after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 0.15rem;
  height: 100%;
  transition: all 0.5s;
}
.arrow::before {
  left: -5px;
  transform: rotate(45deg);
  background-color: #394a6d;
}
.arrow::after {
  left: 5px;
  transform: rotate(-45deg);
  background-color: #394a6d;
}
.open .arrow::before {
  left: -5px;
  transform: rotate(-45deg);
}
.open .arrow::after {
  left: 5px;
  transform: rotate(45deg);
}

.custom-select1-wrapper {
  // position: relative;
  // user-select: none;
  // width: 100%;
  display: flex;
  align-items: center;
}

.custom-select1,
.custom-select1 {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  // border-width: 0 2px 0 2px;
  // border-style: solid;
  // border-color: #394a6d;
}

.custom-select1.open,
.custom-select2.open {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select1__trigger,
.custom-select2__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3b3b3b;
  cursor: pointer;
  top: 2px;
}

.custom-options {
  max-height: 250px;
  overflow-y: scroll;
  position: absolute;
  display: block;
  top: 35px;
  left: 0;
  right: 0;
  background: #fff;
  transition: all 0.5s;
  border: 1px solid #ced4da;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 999;
}
.custom-select1.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.custom-select2.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.custom-option {
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  color: #3b3b3b;
  line-height: 15px;
  cursor: pointer;
  transition: all 0.5s;
}
.custom-option:hover {
  cursor: pointer;
  background-color: #b2b2b2;
}
.custom-option.selected {
  color: #ffffff;
  background-color: #305c91;
}

.tooltip1 {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip1 .tooltiptext {
  visibility: hidden;
  min-width: 200px;
  background-color: #fff;
  color: black;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip1 .tooltiptext.open {
  visibility: visible;
}

@media only screen and (max-width: 400px) {
  input {
    width: 100%;
  }
  .split-two {
    flex-direction: column;

    .form-group {
      width: 100%;
    }
  }
}
