@import "../../../node_modules/animate.css/animate.min.css";

@import "../../theme.scss";




// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(#000, $color, $percentage);
}

.content {
  padding-top: 40px;

  .course-heading {
    color: #000000;

    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 44px;
    margin-bottom: 8px;
  }

  .lesson {
    color: #4d4d4d;

    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .title {
    color: #4d4d4d;

    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 13px;
    margin-bottom: 12px;
  }

  .description {
    color: #4d4d4d;

    font-size: 12px;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 24px;

    ol {
      color: $primary;

      font-size: 12px;
      letter-spacing: 0;
      line-height: 19px;
      margin-left: 12px;
    }
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  .assign{
    background-color: rgba(0, 0, 0, 0.1);
    padding: 28px 24px;
    border-radius: 12px;
    margin-bottom: 24px;

  }

  .not-upload{
    background-color: rgba(0, 0, 0, 0.1);
    align-items: center;
    margin-bottom: 24px;
    padding: 18px 24px !important;
    border-radius: 12px;
  }

  .assignment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 12px;
    padding: 12px 0px;
   

    .assignment-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000000;
      width: 100%;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 19px;

      .icon {
        margin-right: 16px;
      }

      .prac-req {
        margin-left: 40px;
      }

      .mod-info {
        color: #000000;

        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 20px;
      }
    }

    .submit-assignment {
      margin-left: 22px;
    }
  }

  iframe,
  video {
    height: 455px;
    width: 832px;
    border-radius: 12px;
    margin-bottom: 24px;
  }
}

.course-nav-btns {
  display: flex;
  justify-content: space-between;
}

.enroll {
  top: 30%;

  .modal-dialog {
    max-width: 400px;
  }

  .modal-content {
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 4px 10px 10px 10px rgba(0, 0, 0, 0.05);
    padding: 30px 23px;

    p {
      color: #000000;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }
  }

  .actions {
    display: flex;
    justify-content: space-evenly;
  }
}

.bold {
  font-weight: bold;
}

.mod {
  position: relative;
  height: 24px;
  width: 134px;
  border-radius: 12px;
  background-color: #4d4d4d;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px 2px rgba(77, 77, 77, 0.1);
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 13px;
}

.payments {
  h3 {
    text-align: center;
    margin-bottom: 24px;
  }

  .stripe {
    position: relative;
    left: -10px;

    label {
      padding-left: 0;
      width: 100%;
      position: relative;
      left: 25px;
    }
  }

  .DemoPickerWrapper {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background: #eee;
    margin: 24px 0 48px;
    width: 100%;
  }

  .DemoPicker {
    font-size: 18px;
    border-radius: 3px;
    background-color: #eee;
    height: 48px;

    border: 0;
    width: 100%;
    color: #6772e5;
    outline: none;
    background: transparent;

    -webkit-appearance: none;
  }

  .DemoWrapper {
    margin: 0 auto;
    max-width: 500px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .Demo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  label {
    color: black;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 10px;
    display: block;
  }

  .submit {
    display: flex;
    justify-content: center;
    position: relative;
    left: 10px;
  }

  .StripeElement {
    font-size: 16px;
    border-radius: 23px;
    background-color: #eee;
    letter-spacing: 0;
    height: 46px;
    width: 100%;
    color: black;
    padding: 12px 24px 16px;
    border: none;
    margin-top: 5px;
    margin-bottom: 24px;
    margin-left: -15px;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.FpxBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }

  .StripeElement.PaymentRequestButton {
    height: 40px;
  }
}

.bg {
  background-color: #f4f4f4;
  padding: 48px 40px 69px;
  border-radius: 12px;
  position: relative;
}

@media only screen and (max-width: 450px) {
  .payments .left-half {
    margin-right: 0 !important;
  }

  .assignment-details {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  .icon {
    margin-right: 0 !important;
    margin-bottom: 16px;
  }

  .assignment-content {
    margin-bottom: 16px;
  }

  .bg {
    padding: 48px 20px 69px;
  }

  button {
    margin: 3px !important;
  }

  .prac-req {
    margin: 0 !important;
  }

  .course-overview h2 {
    text-align: center;
    padding-left: 0 !important;
  }
}

.submitBtn {
  margin: 0 !important;
}


.upload-assignment {
  // padding-bottom: 1.2em;
  padding-left: 1em;

  .upload-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: flex-end;
  }

  .progress {
    height: 40px !important;
    width: 180px;
    border-radius: 2px !important;
    border-radius: 30px !important;
    margin-bottom: 0px !important;

    .progress-bar {
      background-color: $primary !important;
    }
  }

  .rs-uploader-trigger-btn {
    background-color: $primary;
    color: #fff !important;
    border-radius: 24px !important;
    font-weight: 600 !important;

    &:hover {
      background-color: shade($primary, 20%) !important;
    }

  }

  &.uploaded {
    .rs-uploader-trigger-btn {
      background-color: #a8a8a8 !important;
      color: #ffffff !important;
      pointer-events: none;
      cursor: not-allowed;
      // font-size: 16px;
      // font-weight: 600;
      // letter-spacing: 0;
      // line-height: 16px;

      &:hover {
        background-color: #a8a8a8 !important;
      }

    }
  }
}

.upload-btn-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}



.audio-player {
  padding: 1em 0em;
  display: grid;
  grid-template-columns: auto 1fr;

  &__logo {
    display: flex;
    align-items: center;
    padding-right: 0.5em;

    img {
      height: 75px;
      object-fit: cover;
    }
  }






  .rhap_container {
    box-shadow: none !important;
    padding: 0px !important;
    background-color: transparent !important;

    .rhap_additional-controls {
      display: none !important;
    }

    .rhap_volume-container {
      display: none !important;
    }

    .rhap_main-controls {
      width: 100% !important;
      display: flex;
      justify-content: space-between !important;
      flex-direction: row !important;
      align-items: center !important;

      button {
        svg {
          color: $primary !important;
        }
      }
    }

    .rhap_progress-bar {
      div {
        background-color: $primary;
      }

      .rhap_download-progress {
        background-color: tint($primary, 70%);
      }
    }

    .rhap_current-time {
      color: $primary !important;
    }

    .rhap_total-time {
      color: $primary !important;

    }
  }

}

@media screen and (max-width: 600px) {

  .audio-player {
    display: flex;

    &__logo {
      display: none;
    }
  }

  .course-nav-btns {
    display: flex;
    flex-direction: column;
    ;
  }

  .upload-btn-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .upload-assignment {
    padding-left: 0em;
    padding-top: 1em;
  }
}

.assignment__download-details{
  div{
    display: flex;
    flex-direction: row;
    align-items: center;

    a{
      text-decoration: none;
      padding-left: 10px;
      cursor: pointer;
      font-weight: 500 !important;
      color:$primary !important;
    }
  }
}

.pdf-viewer{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  display: block;
 
  &.close{
    display: none !important;

    .pdf-viewer__body__content{
      animation:fadeOutDown;
      animation-duration: 0.4s;
    }
  }
  &__overlay {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    height: 100%;
    // pointer-events: none;
}
&__body{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  


    &__content{
      padding: 24px;
      background-color: #fff;
      border-radius: 12px;
      animation:fadeInUp;
      animation-duration: 0.4s;
    }
    &__closeBtn{
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 999999;

      svg{
        color: $primary !important;
        cursor: pointer;
      }
    }
}

  .strikeoutAnnotation{
    height: 100% !important;
    width: 100% !important;
  }

  .react-pdf__Page{
    
  }

  &__pageChanger{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 12px;
    &__box{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      p{
        padding: 0px 12px;
        @media #{$xs-layout}{
         font-size: 0.7em !important;
        }
      }
      .rs-btn{
        @media #{$xs-layout}{
          padding: 0px !important;
         }

        
      }
      button{
        @media #{$xs-layout}{
          width: 40px !important;
         }
       }
    }
  }

  //Responsive

  .react-pdf{
    &__Document{
      overflow-x: hidden !important;
    }

    &__Page{

      &__canvas{
        @media #{$xs-layout}{
          width: 100% !important;
          height: 100% !important;
        }
      }
      &__textContent{
        @media #{$xs-layout}{
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
}