@import "../../theme.scss";


.stepper1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .disableClick{
    pointer-events: none;
  }

  .step-container {
    cursor: pointer;
      position: relative;
    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 49px;
      height: 49px;
      border: 1.5px solid  #4D4D4D;
      border-radius: 50%;
      font-size: 26px;
      color:#4D4D4D;
    }

    .currentlesson{
      border-color: $primary!important;
      color:$primary!important;
    }

    .incomplete{
      border-style: dashed;
      border-color:#4d4d4d;
      color:#4d4d4d;
      pointer-events: none;
    }

    .barline {
        border-top: 1px solid #4d4d4d;
        position: relative;
        top: -25px;
        left: 50px;
        width: 0;
    }
    .incomplete-barline{
      // border-top-style: dashed!important;
      border-color:#4d4d4d;
    }
  }

  .step-container:last-child .barline{
      display: none !important;
  }
}



@media only screen and (max-width: 450px) {

  .stepper1 .step-container .step {
    width: 26px;
    height: 26px;
    border: 1.5px solid  #4D4D4D;
    border-radius: 50%;
    font-size: 13px;
    color:#4D4D4D;
  }

  .stepper1 .step-container .barline{
    top: -13px;
    left:26px;
  }
}