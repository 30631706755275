@import "../../theme.scss";


.general {
  background-color: #f4f4f4 !important;
}
.course{
  color: $primary!important;
}

.page-heading{
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: bold;
}

.welcome {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .forum-actions {
    display: flex;
    align-items: center;

    .add-question {
      color: $primary;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      margin-right: 24px;
      cursor: pointer;
    }

    .search {
      position: relative;

      input {
        width: 269px;
        border-radius: 23px;
        background-color: #f4f4f4;
      }

      .search-text {
        background-color: #ffffff;
        box-shadow: 10px 10px 30px 1px rgba(0, 0, 0, 0.1);
        color: $primary;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 17px;
        outline: none;
      }

      input:focus {
        background-color: #ffffff;
        box-shadow: 10px 10px 30px 1px rgba(0, 0, 0, 0.1);
        color: $primary;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 17px;
        outline: none;
      }
    }
    .search {
      display: inline-block;
      position: relative;
      color: $primary;
      top: -2px;
    }
    .search:after {
      font-family: "FontAwesome";
      content: "\f002";
      top: 10px;
      position: absolute;
      right: 20px;
      transform: rotate(90deg);
      font-size: 18px;
    }
  }
}

.forum-post {
  border-radius: 12px;
  background-color: rgba(119, 19, 66, 0.1);
  color: #000000;
  padding: 24px 48px 24px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  cursor: pointer;

  .lefthalf {
    display: flex;
    align-items: center;
  }

  img {
    border-radius: 50%;
    margin-right: 24px;
  }

  .date {
    // color: #000000;
   
    font-size: 8px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 10px;
  }

  .name {
    // color: #000000;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
  }

  .question {
    // color: #000000;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 17px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .reply {
    padding: 4px 7px;
    color: $primary;
    font-size: 9px;
    width: 65px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 10px;
    border-radius: 4px;
    background-color: rgba(119, 19, 66, 0.2);
  }
  .icon {
    margin-left: 32px;
    color: #000000;
  }
}

.question-asked-by {
  color: #4d4d4d;
  padding: 29px 73px 29px 40px;
  // opacity: 0.1;
  border-radius: 12px;
  background-color: rgba(119, 19, 66, 0.1);
  // background-color: $primary;
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  img {
    border-radius: 50%;
    margin-right: 24px;
  }

  .asked-time {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 13px;
    margin-bottom: 8px;
  }

  .author {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 5px;
  }

  .question {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 28px;
  }
}

.comments {
  border-radius: 12px;
  color: #4d4d4d;
  background-color: rgba(119, 19, 66, 0.1);
  margin-left: 19px;
  margin-right: 26px;
  padding: 42px 40px 61px;

  .comment {
    // padding-top: 24px;
    display: flex;
    align-items: center;
    // margin-bottom: 24px;
    // padding: 24px 0;

    img {
      border-radius: 50%;
      margin-right: 28px;
    }

    .details {
      border-bottom: 1px solid rgba(119, 19, 66, 0.5);
      border-radius: 0.5px;
    }

    .commented-time {
      font-size: 10px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 10px;
      margin-bottom: 8px;
    }

    .commented-by {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 10px;
    }

    .comment-text {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
  .hr {
    display: block;
    border: 1px solid rgba(119, 19, 66, 0.5);
    opacity: 0.5;
    border-radius: 0.5px;
    width: 84%;
    margin: 24px 0 24px auto;
  }
}
.add-comment {
  position: relative;

  textarea {
    margin-top: 41px;
    resize: none;
    border: none;
    outline: none;
  }
  ::placeholder {
    /* Internet Explorer 10-11 */
    color: $primary;
    font-size: 16px;
  }

  textarea:focus {
    width: 100%;
    height: 100px;
    border: none;
    outline: none;
  }

  .icon {
    position: relative;
    top: -17px;
    left: -30px;
    display: inline-block;
    color: $primary;
  }

  .expand {
    height: 48px;
    width: 340px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 17px;
    border-radius: 23px;
    background-color: #ffffff;
    padding: 15px 24px;
  }
}
textarea:focus + .icon {
  display: none;
}

.addQuestion {
  top: 30% !important;
  .modal-dialog {
    max-width: 400px;
  }

  .modal-content {
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 4px 10px 10px 10px rgba(0, 0, 0, 0.05);
    padding: 30px 23px;

    p {
      color: #000000;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin-bottom: 25px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-evenly;
  }
}

.course-name {
  color: #4d4d4d;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 24px;
  text-transform: capitalize;
}

.write {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 16px;
}

.text-container {
  border-radius: 12px;
  background-color: rgba(119, 19, 66, 0.1);
  padding: 40px 40px 71px;
  margin-bottom: 100px;

  color: $primary;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;

  textarea {
    resize: none;
    color: #4d4d4d;
    font-size: 18px;
    margin: 32px 0;
    padding: 24px;
    height: 473px;
    width: 839px;
    border-radius: 8px;
    background-color: #ffffff;
    outline: none;
  }
}

@media only screen and (max-width: 450px) {
  .question-asked-by{
    padding: 25px;
  }
  .question-asked-by img,.comments .comment img{
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }
  .comments{
    margin: 0 auto;
    padding:25px 25px 68px;

    .icon{
      display: none;
    }
  }

  .commented-by{
    word-break: break-all;
  }

  .add-comment .expand,textarea{
    width: 100%;
  }
}