@import "../../theme.scss";


// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";

.userProfile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 36px;

  .userimage {
    text-align: center;
    margin-right: 30px;

    img {
      border-radius: 50%;
      width: 216px;
      height: 216px;
      margin: 15px 0 15px;
    }
  }

  .user-info {
    margin-top: 30px;


  }
}

.mb-24 {
  margin-bottom: 24px;
}

label {
  padding-left: 15px;
  color: black;

  font-size: 10px;
  letter-spacing: 0;
  line-height: 10px;
  display: block;
}

input,
.googleAddress {
  font-size: 16px;
  border-radius: 23px;
  background-color: #eee;
  letter-spacing: 0;
  height: 46px;
  width: 340px;
  outline: none;
  color: black;
  padding: 16px 24px;
  border: none;


}

.google-places-autocomplete__suggestions-container {
  z-index: 999 !important;
}

.edit-password {
  color: $primary;
  position: relative;
  left: 12px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}

.left-half {
  margin-right: 100px;

}

.student-info {
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-start;
  // margin-top: 24px;
  // margin-bottom: 50px;

  .mb-24 input {
    cursor: default;
  }

  .left-half {
    margin-right: 100px;

  }
}

.page-heading {
  color: #000000;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  margin-bottom: 20px;
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 222px;
  width: 322px;
  border: 2px dashed #4D4D4D;
  opacity: 0.6;
  border-radius: 8px;

  .text {
    color: #4D4D4D;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    margin: 15px 0 12px;
  }

  .subtext {
    color: $primary;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 13px;
  }
}

.pt-2 {
  padding-top: 10px !important;
}

@media only screen and (max-width: 400px) {
  .pt-2 {
    padding-top: 20px !important;
  }

  .billing-info {
    .left-half {
      text-align: center;

      button {
        margin-bottom: 15px;
      }
    }
  }

  .userProfile .userimage {
    margin-right: 0;
  }

  .userProfile {
    flex-direction: column;
  }

  .left-half {
    margin: 0;
  }

  .student-info,
  .program-info {
    flex-direction: column !important;

    .left-half {
      margin: 0;
    }
  }

  .edit-password {
    top: 15px;
    left: 10px;
    font-size: 16px;

  }
}

@media only screen and (max-width: 600px) {
  .userProfile {
    flex-direction: column;
  }
}

/**
   Animation Keyframes
**/
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes bounce {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  50% {
    opacity: 0.7;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translate(0px);
  }
}

.image-uploader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;


}

.upload-image-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.success {
  color: $primary;

}

h1 {
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 30px;
}

.program-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 31px;
  margin-bottom: 5px;

  &__buttons {
    display: flex;
    flex-direction: row;

    @media #{$xs-layout} {
      flex-direction: column;
    }
  }
}

.pass-info {
  text-align: right;
  padding-right: 15px;
}


#myProgress {
  width: 278px;
  background-color: grey;
  margin: 18px 0 18px;
  border: 5px;
}

#myBar {
  width: 100%;
  height: 2px;
  background-color: $primary;
}


.certificate-download {
  margin-bottom: 15px;

  span {
    padding-right: 14px;
  }
}